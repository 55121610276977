import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.dialogVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dialogVisible) = $event)),
    title: "角色分配",
    width: "500px"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_el_checkbox_group, {
          modelValue: _ctx.checkedRoleList,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkedRoleList) = $event))
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sysRoles, (item, index) => {
              return (_openBlock(), _createBlock(_component_el_checkbox, {
                onChange: 
            (v) => {
              _ctx.saveUserRole(item.id, v);
            }
          ,
                key: index,
                label: item.id
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.roleName), 1)
                ]),
                _: 2
              }, 1032, ["onChange", "label"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}