
import { defineComponent, inject, ref } from "vue";
import { UserModel } from "@/apis/interface/IUser";
import { IUserRole, UserRoleModel } from "@/apis/interface/IUserRole";
import { ISysRole, SysRoleModel } from "@/apis/interface/ISysRole";
import { RespBodyList } from "@/apis/interface/Resp";
import { ElMessage } from "element-plus";

export default defineComponent({
  setup() {
    const apiUserRole = inject("apiUserRole") as IUserRole;
    const apiSysRole = inject("apiSysRole") as ISysRole;
    let dialogVisible = ref(false);

    let userData: UserModel;
    let sysRoles = ref<Array<SysRoleModel>>([]);
    let checkedRoleList = ref<Array<number | undefined>>([]);

    let setData = async (user: UserModel) => {
      if (user.id) {
        userData = user;
        dialogVisible.value = true;

        let ret1 = (await apiSysRole.getList(
          "applet"
        )) as RespBodyList<SysRoleModel>;
        sysRoles.value = ret1.object;

        let ret2 = (await apiUserRole.getList(
          user.id,
          undefined
        )) as RespBodyList<UserRoleModel>;

        checkedRoleList.value = ret2.object.map((item) => item.roleId);
      }
    };

    let saveUserRole = async (roleId: number, value: boolean) => {
      if (userData.id) {
        let result = await apiUserRole.update(userData.id, roleId, value);
        if (result.object) {
          ElMessage({
            type: "success",
            message: "修改角色成功"
          });
        } else {
          ElMessage({
            type: "error",
            message: "修改角色失败"
          });
        }
      }
    };

    return {
      dialogVisible,
      sysRoles,
      checkedRoleList,
      setData,
      saveUserRole
    };
  }
});
